// Bring in a few things from Bootstrap so we can use the grid, clearfix and vendor mixins (for transition, etc)
@import "../../../../../profiles/wcmc/themes/wcmc_bootstrap/scss/bootstrap/variables";
@import "../../../../../profiles/wcmc/themes/wcmc_bootstrap/scss/bootstrap/mixins/_clearfix";
@import "../../../../../profiles/wcmc/themes/wcmc_bootstrap/scss/bootstrap/mixins/_grid";
@import "../../../../../profiles/wcmc/themes/wcmc_bootstrap/scss/bootstrap/mixins/_vendor-prefixes";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../../profiles/wcmc/themes/wcmc_bootstrap/scss/_variables";
@import "../../../../../profiles/wcmc/themes/wcmc_bootstrap/scss/_mixins";
@import "mixins";

/* =============================================================================
   ~Base Elements
   ========================================================================== */
.front #main {
  max-width: none;
  margin: 0;
  padding: 0;
}

#main {
  padding: 2.2rem 0.2rem 0 0.2rem;
  @include breakpoint($md) {
    padding: 2.2rem 0 0 0;
  }
}

header {
  .brand__lockup .container {
    position: relative;
    top: 0.4rem;
    img {
      height: 98px;
    }
  }
}

footer {
  padding: 0;
  .footer-contact {
    .footer-contact__site-name a {
      font-size: 15px;
      @include breakpoint($md) {
        font-size: 18px;
      }
    }
  }
  .footer-site {
    .footer-address {
      border-right: none;
    }
    .footer-nav {
      margin-bottom: 2rem;
      border-left: 1px solid #DDD;
      .block {
        @include mi-multi-column;
        @include mi-multi-gap;
        ul.menu {
          width: 85%;
        }
      }
    }
  }
}
/* =============================================================================
   ~Override main bootstrap theme
   ========================================================================== */
#main-nav {
  ul {
    li {
      &.level-1 {
        padding: 0 15px;
      }
      &.home {
        padding: 0;
      }
    }
  }
}


.panel-hero-3col, .panel-footer-3col, .panel-twocol, .panel-fullhero-3col {
  padding-bottom: 2rem;
  .hero-section {
    figure.hero-image {
      img {
        width: 100%;
      }
    }
  }
  .main-content {
    .panel-pane {
      > div {
        > h2:first-child,
        > h3:first-child,
        > h4:first-child,
        > h5:first-child,
        > h6:first-child {
          margin-top: 0;
        }
      }
      .view-biblio-views {
        > div {
          h2:first-child,
          h3:first-child,
          h4:first-child,
          h5:first-child,
          h6:first-child {
            margin-top: 0;
          }
        }
      }
      div.caption-left {
        margin: 0.5rem 2.4rem 0.5rem 0;
      }
      div.caption-right {
        margin: 0.5rem 0 0.5rem 2.4rem;
      }
      div.caption-inner {
        padding: 0;
      }
      div.caption p.caption-text {
        margin: 0.5rem 0 1rem 0;
      }
      div.caption img, div.caption object {
        margin-bottom: 1rem;
      }
      .clear-left {
        clear: left;
      }
    }
  }
}

/* =============================================================================
   ~Subtheme Custom base elements
   ========================================================================== */
.panel-hero-3col {
  .main-content {
    .panel-pane {
      .pagination {
        margin-top: 3rem;
      }
      .view-biblio-views {
        .biblio-details {
          padding: 1rem 0px;
          .views-field {
            line-height: 2.8rem;
            background-color: #F6F6F6;
            border-bottom: 1px solid #DDD;
            font-size: 1.6rem;
            padding: 0.8rem;
            .views-label {
              display: inline;
              @include breakpoint($md) {
                width: 22%;
                float: left;
                display: inline-block;
              }
            }
            .field-content {
              display: inline;
              @include breakpoint($md) {
                width: 78%;
                display: inline-block;
              }
            }
            &:before {
              clear: both;
            }
          }
        }
      }
      .view-filters {
        form {
          .views-widget {
            .select2 {
              min-width: 30rem;
            }
          }
        }
        #edit-tid-wrapper {
          label {
            margin-top: 1.2rem;
          }
          .views-widget {
            float: right;
            margin-left: 1rem;
          }
        }
      }
      .node-webform {
        padding-top: 1.2rem;
        .submitted {
          display: none;
        }
        .webform-client-form {
          .webform-component--glossary-term {
            float: left;
            label {
              text-align: left;
              display: inherit;
              margin-bottom: 0;
            }
          }
          .form-actions {
            float: left;
            position: relative;
            bottom: 1.4rem;
            margin-left: 0.6rem;
          }
        }
        > h2 {
          display: none;
        }
      }
      .view-display-id-panel_pane_6 {
        padding-bottom: 1rem;
      }
      &:after {
        clear: both;
      }
      .view-id-news {
        .view-teaser {
          margin-bottom: 2rem;
          .views-field-term-node-tid {
            font-size: 1.3rem;
            a {
              font-size: 1.3rem;
            }
          }
          .post-date {
            font-size: 1.3rem;
          }
          .news-featured-image {
            float: left;
            padding-top: 2.2rem;
            padding-right: 2.4rem;
          }
          .teaser-image {
            @include breakpoint($md) {
              float: left;
              width: 22%;
              + .teaser-text {
                float: right;
                width: 76%;
                margin-top: -0.2rem;
                margin-right: 0.5rem;
              }
            }
          }
        }
        .item-list {
          margin-top: -1rem;
        }
      }
      .teaser-image img.panopoly-image-quarter {
        margin-top: 0.5em;
        @include breakpoint($md) {
          height: auto;
          float: left;
          margin: 0 2rem 0 0;
        }
        @include clearfix;
      }
      .btn-instruction {
        width: 270px;
        margin: initial;
      }
    }
  }
}

.panel-twocol {
  .main-content {
    .post-date {
      float: left;
      padding-right: 1.4rem;
    }
    .pane-node-body {
      margin-top: 2rem;
    }
    .addthis_toolbox {
      position: relative;
      top: 0.4rem;
    }
    .list-inline {
      position: relative;
      top: 1.7rem;
      left: 0.2rem;
    }
    .pane-node-field-news-category {
      .field-type-taxonomy-term-reference {
        .taxonomy-term-reference-0 {
          &:before {
            content: "Tagged:\0000a0";
            font-weight: 700;
          }
        }
      }
    }
    .news-featured-image {
      float: left;
      padding-top: 2.2rem;
      padding-right: 2.4rem;
    }
  }
}

.panel-header-3col {
  .header-3col-header {
    background: none repeat scroll 0% 0% #373737;
    position: relative;
    height: 100%;
    max-height: 400px;
    .home-hero {
      background-color: #f7f7f7;
      img {
        width: 1000px;
        height: auto;
        margin: 0 auto;
        display: block;
      }
    }
    .panel-pane {
      overflow: hidden;
    }
  }
  #header-3col-bottom {
    padding-bottom: 1.4rem;
    .teaser-image img.panopoly-image-quarter {
      margin-top: 1.25em;
      @include breakpoint($md) {
        height: auto;
        float: left;
        margin-left: 0;
        margin-right: 2rem;
        margin-top: 2rem;
      }
      @include clearfix;
    }
  }
}

.panel-footer-3col {
  .main-content {
    .panel-pane {
      .btn-full-profile {
        display: inline-block;
        width: 14.5rem;
      }
      .btn-cv {
        display: inline-block;
        width: 28rem;
      }
      .contact-us-grid {
        padding-bottom: 1rem;
        .view-content {
          .views-row-odd {
            padding-bottom: 1.2rem;
            @include breakpoint($sm) {
              float: left;
              width: 51%;
              &:before {
                clear: both;
              }
            }
          }
          .views-row-even {
            padding-bottom: 1.2rem;
            @include breakpoint($sm) {
              width: 49%;
              float: right;
            }
          }
        }
        .btn-contact-us-questionnaire {
          width: 24rem;
          margin: 1rem 0;
        }
      }
    }
    .pane-node-field-biographical-info {
      .pane-title {
        margin-top: 3rem;
      }
    }
    .publication-wrapper {
      border-top: 1px solid #DDD;
      ul {
        padding-top: 1.4rem;
      }
      .pagination {
        padding: 1rem 0px 1.6rem 0;
      }
    }
    .contact-us-address {
      clear: both;
      border-top: 1px solid #DDD;
      width: 100%;
      padding-top: 2.2rem;
      letter-spacing: -0.04rem;
      > span {
        font-weight: 600;
      }
    }
    .pane-bundle-map {
      iframe {
        padding-top: 1rem;
        width: 100%;
        max-width: 100%;
        height: 45rem;
      }
    }
  }
}

.footer-3col-container {
  width: 100%;
  z-index: 1;
  .footer-3col-layer {
    background-color: rgba(235, 230, 225, 1.0);
    .footer-3col-inner {
      margin-top: 1.4rem;
      //padding: 0.1rem;
      //background-image: url("../images/home_pane_bg.png");
      background-color: #F7F7F7;
      overflow: auto;
      .panel-pane {
        padding: 3rem 0 1rem 0;
        blockquote {
          color: #A2A2A2;
          font-size: 1.6rem;
          line-height: 3rem;
          margin-left: 2rem;
          cite {
            float: right;
            font-size: 1.4rem;
            &:before {
              content: "- ";
            }
          }
        }
      }
    }
  }
}

#header-3col-content, #header-3col-bottom, #header-3col-footer {
  .pane-title {
    font-size: 2.4rem;
    font-weight: 300;
    //color: $primary-color;
    text-transform: none;
    text-align: center;
    margin: 2.8rem 0px 2rem 0;
  }
  .pane-content {
    font-size: 1.6rem;
    margin: 2rem 1rem 0 1rem;
    @include breakpoint($md) {
      margin: 2rem 0 0 0;
    }
  }
}

#header-3col-body {
  background-color: #EAE6E3;
  background-image: url("../images/home_pane_bg.png");
  overflow: auto;
  .pane-title {
    font-size: 2.8rem;
    color: #5A83B0;
    margin: 0;
    @include breakpoint($md){
      font-size: 2.4rem;
    }
    + .pane-content {
      color: #5A83B0;
      font-size: 1.5rem;
      font-weight: 100;
    }
  }
}

#header-3col-bottom {
  overflow: auto;
}

#header-3col-footer {
  background-color: #F7F7F7;
  //background-image: url("../images/home_pane_bg.png");
  position: relative;
  opacity: 1.0;
}

#header-3col-footer-layer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  opacity: .9;
}

#header-3col-bottom-container div:nth-child(3) p,
#header-3col-content p {
  text-align: center;
}

#header-3col-footer {
  padding-bottom: 2rem;
  .container {
    color: #fff;
    .footer-column-inner {
      .view-content {
        .headshot-entry {
          float: left;
          position: relative;
          padding: 5px;
          img {
            min-width: 100% !important;
          }
          .headshot-details {
            position: absolute;
            top: 0px;
            text-align: center;
            z-index: 100;
            margin-top: 5px;
            background-color: rgba(0, 0, 0, 0.2);
            overflow: hidden;
            width: 93%;
            @include breakpoint($sm) {
              min-width: 95% !important;
            }
            @include breakpoint($md) {
              min-width: 94% !important;
            }
            hr {
              border: 1px solid #eee;
              width: 60%;
            }
            a {
              color: #eee;
            }
            .name {
              margin-top: 60%;
              font-size: 1.6rem;
              text-transform: uppercase;
              font-weight: 400;
              line-height: 2rem;
              margin-bottom: -2rem;
              width: 95%;
              @include breakpoint($sm) {
                font-size: 1.8rem;
                margin-top: 65%;
              }
            }
            .profile-link {
              font-size: 1.2rem;
              margin-top: -2rem;
              @include breakpoint($sm) {
                font-size: 1.35rem;
                margin-top: -1.2rem;
              }
            }
          }
          &:nth-child(2n+1) {
            clear: none !important;
          }
        }
      }
    }
  }
}

#header-3col-left, #header-3col-center, #header-3col-right {
  max-width: 100%;
  margin: 4.5rem 0rem 3.8rem 0;
  .header-column-inner {
    width: 90%;
    background-color: #FFF;
    margin: auto;
    @include box-sizing('20%');
    border-radius: 4px;
    @include box-shadow(2px 2px 2px 0px #bbbbbb);
    @include clearfix;
    div:nth-child(1) { //.panel-pane
      text-align: center;
      .pane-title {
        margin-top: 2rem;
      }
    }
    p.header-3col-image {
      position: relative;
      border: 0.6rem solid #ffffff;
      overflow: hidden;
      margin-bottom: 0;
      border-radius: 4px;
      &:before, &:after {
        position: absolute;
        top: -17px;
        left: 49%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        //pointer-events: none;
        @include transition(all .3s ease-in-out);
      }
      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #fff;
        border-width: 17px;
        margin-left: -17px;
      }
      > span {
        margin: 0;
        height: 100%;
        @include mi-opacity(0.6);
        &:hover {
          @include transition(all .3s ease-in-out);
          @include mi-opacity(1);
        }
        img {
          border-radius: 0px !important;
          @include transition(all .3s ease-in-out);
          &:hover {
            @include transition(all .3s ease-in-out);
            @include mi-opacity(1);
            @include mi-transform-scale(1.1);
          }
        }
      }
      &:hover:after {
        top: 0px;
      }
      ul {
        padding: 0;
        list-style: outside none none;
        li {
          padding-bottom: 0.4rem;
        }
      }
      img {
        border-radius: 1px;
        width: 100%;
      }
    }
  }
}

#header-3col-left {
  .header-column-inner {
    @include breakpoint($md) {
      float: left;
    }
  }
}

#header-3col-right {
  .header-column-inner {
    @include breakpoint($md) {
      float: right;
    }
  }
}

#header-3col-center {
  .header-column-inner {
    @include breakpoint($md) {
      margin-left: 5%;
      margin-right: 5%;
    }
  }
}

/* =============================================================================
   ~Body main page
   ========================================================================== */
#main-content {
  .pane-node-title {
    margin-bottom: 2rem;
  }
  .teaser-title {
    margin-bottom: 1rem;
    //font-size: 2.1rem;
    font-weight: 100;
  }
  .pane-faq-panel-pane-3 {
    float: left;
  }
  .pane-glossary-panel-pane-1 {
    float: right;
  }
  .pane-faq-panel-pane-3, .pane-glossary-panel-pane-1 {
    width: 49.5%;
    margin-bottom: 3rem;
    .pane-title {
      text-align: left;
      margin: 1rem 0 0 0;
    }
    .views-field {
      padding: 1rem 0;
    }
  }
  .pane-node-field-profile-image {
    float: left;
    margin: 0 1.5rem 1rem 0;
  }
  .pane-node-field-professional-title {
    @include clearfix;
  }
  h3 {
    &:not(:first-of-type) {
      margin-top: 2.4rem;
    }
  }
  .ss-upright:before {
    float: right;
    font-size: 10px;
    padding: 2px;
  }
  .field-name-field-professional-title {
    font-size: 1.4rem;
    font-weight: 600;
    .field-item {
      padding-bottom: 1.5rem;
    }
  }
  .pane-node-field-pops-profile-link {
    .pane-content {
      padding: 8px 16px;
    }
  }
  .pane-content {
    p {
      line-height: 2.37rem;
    }
    .view-male-infertility-custom {
      ul {
        list-style-type: none;
        li {
          margin: 0 0 2rem 0;
          display: inline-block;
          &:not(:last-child):after {
            content: "";
            display: block;
            height: 1px;
            margin-top: 18px;
          }
          div.field-content {
            display: inline;
          }
        }
        .views-row-odd {
          float: left;
          width: 40%;
        }
        .views-row-even {
          float: right;
          width: 40%;
        }
      }
      .view-content {
        font-size: 1.54rem;
        padding: 0px 20px 15px 0px;
      }
    }
    .view-news {
      label {
        display: inline-block;
        margin-right: 1rem;
      }
      img {
        float: left;
        margin-top: 0.5rem;
        margin-right: 2rem;
        border-radius: 0px;
        @include clearfix;
      }
      .views-field-term-node-tid, .post-date {
        margin: 0px 0.5rem 0.5rem;
        font-size: small;
      }
      .view-teaser  {
        .panel-twocol & {
          margin-bottom: 3rem;
          padding-bottom: 3rem;
        }
        .panel-header-3col & {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
    .views-widget {
      display: inline-block;
      height: 5rem;
      select {
        border: 1px solid #E0E0E0;
        background: transparent;
      }
    }
    .views-field-field-phone {
      padding: 0rem 0px 1.4rem 0;
    }
    .attachment {
      min-height: 14rem;
      clear: both;
    }
    .pane-content-divider {
      height: 1px;
      margin: 0px;
    }
    .male-infertility-staff {
      ul {
        list-style: outside none none;
        padding: 0px;
        li {
          float: left;
          width: 50%;
          line-height: 0px;
        }
      }
    }
    .mi-contact-us-list {
      margin: 1.2rem 0.5rem 0px 0.5rem;
      @include breakpoint($md) {
        float: left;
        width: 48%;
        height: 14rem;
        .wcmc-contact-button {
          max-width: 75%;
          font-size: 1.4rem;
        }
      }
      @include breakpoint($md) {
        .wcmc-contact-button {
          font-size: 1.2rem;
        }
      }
    }
    .faq-list {
      .element__hide--open {
        display: block !important;
      }
      &:last-of-type {
        margin-bottom: 3rem;
      }
    }
  }
  @include clearfix;
}

.information-column {
  .information-sidebar {
    z-index: 10;
    font-size: 1.3rem;
    color: #777;
    .panel-pane {
      h4 {
        text-transform: uppercase;
      }
      ul, ol {
        list-style-type: none;
        padding-left: 0;
        li {
          margin: 0 !important;
          line-height: 3rem;
        }
      }
      article {
        > h2 {
          display: none;
        }
        .content {
          text-align: center;
          form {
            position: relative;
            display: inline-block;
            max-width: 22rem;
            margin-top: 1rem;
            .form-item {
              margin-bottom: 0px;
              text-align: left;
              input[type=text] {
                padding: 8px 0px 8px 8px;
                border: 1px solid #DDDDDD;
                font-size: 1.3rem;
                height: 4rem;
                box-shadow: unset;
              }
            }
            .form-actions {
              float: right;
              position: absolute;
              right: 0;
              bottom: 0;
              margin-bottom: 0;
              button {
                border: medium none;
                background: transparent none repeat scroll 0% 0%;
                .cta-icon-search {
                  color: #CF4520;
                  fill: #E87722;
                  cursor: pointer;
                  font-size: 2.4rem;
                }
              }
            }
          }
        }
      }
      .submitted {
        display: none;
      }
      .wcm-cta {
        margin-bottom: 0.6rem;
        margin-top: 1.6rem;
        .wcm-cta__tile {
          width: 110px;
          height: 110px;
          @include breakpoint($md) {
            width: 102px;
            height: 102px;
          }
          @include breakpoint($lg) {
            width: 110px;
            height: 110px;
          }
        }
        //.wcm-cta__tile:nth-child(1) {
        //  border-width: 1px;
        //  border-radius: 4px;
        //}
        //.wcm-cta__tile:nth-child(2) {
        //  border-width: 1px 1px 1px 0;
        //  border-radius: 4px;
        //}
        .cta-icon-youtube {
          &::before {
            content: "";
            font-size: 30px;
            font-family: "fontello";
            font-style: normal;
            font-weight: normal;
            display: inline-block;
            text-decoration: inherit;
            text-align: center;
            font-variant: normal;
            text-transform: none;
            line-height: 1em;
            -moz-osx-font-smoothing: grayscale;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
    .pane-bundle-basic-file:after {
      content: "";
      display: block;
      height: 1px;
      margin: 3rem 0 1.5rem 0;
      background: none repeat scroll 0% 0% #A2A2A2;
    }
    .pane-sidebar-contact-pane {
      .contact-info {
        text-align: left;
        max-width: 220px;
        margin: 0 auto;
        .site-name {
          font-size: 1.4rem;
          color: #000;
        }
      }
    }
    .view-male-infertility-custom {
      max-width: 220px;
      margin: 0px auto;
      .side-bar-contact-name {
        float: left;
        display: inline-block;
        width: 50%;
        margin: 0px;
        &:before {
          clear: both;
        }
      }
      .side-bar-contact-phone {
        display: inline-block;
        text-align: right;
        margin: 0px;
        width: 50%;
      }
      &:after {
        clear: both;
      }
      .btn--wcm {
        width: auto;
        max-width: 220px;
      }
    }
    .views-field-field-patient-questionnaire {
      margin: 2.2rem 0px;
    }
    .ss-phone {
      text-align: left;
      width: 220px;
      margin: 0 auto;
    }
    @include breakpoint($md) {
      margin: 0;
    }
  }
}

.headshot-grid {
  .headshot-entry {
    float: left;
    min-width: 50%;
    margin: 0 auto 2rem auto;
    .headshot {
      vertical-align: top;
      margin-right: 0.5rem;
    }
    .headshot-details {
      display: inline-block;
      position: relative;
      p {
        line-height: 1.6rem !important;
      }
      .title span {
        display: block;
        padding: 0;
        margin: 0;
      }
    }
    &:nth-child(2n+1) {
      clear: both;
    }
  }
}

#main-container-left {
  margin-top: 2.2rem;
  clear: both;
  @include breakpoint($md) {
    float: left;
  }
  .fieldable-panels-pane {
    .btn-visit-ivf-center {
      width: 25rem;
      margin: 0 3rem;
    }
  }
}

#main-container-right {
  margin-top: 2.2rem;
  @include breakpoint($md) {
    float: right;
  }
  .fieldable-panels-pane {
    .btn-visit-ivf-center {
      width: 25rem;
      margin: 0 3rem;
    }
  }
}

#panels-ipe-regionid-main_footer {
  z-index: 1;
}

/**
 * autocomplete ajax
 */
#autocomplete {
  font-size: 1.3rem;
  p {
    margin: 0;
    padding: 0;
  }
  li {
    white-space: normal;
    padding: 4px 2px;
  }
  .autocomplete-body-text {
    margin: 0;
    padding: 0;
    line-height: 1.8rem;
    .autocomplete-body-more {
      font-size: 1.1rem;
    }
  }
}

.global-search select {
  font-size: 1.2rem;
  width: 40%;
  background-position: 95%;
}

.ajax-progress-throbber {
  display: none !important;
}
