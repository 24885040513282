/* =============================================================================
   ~MI custom mixins
   ========================================================================== */
@mixin mi-filter($effect:null, $size:5px) {
  @if ($effect) {
    -moz-filter: $effect($size);
    -o-filter: $effect($size);
    -ms-filter: $effect($size);
    -webkit-filter: $effect($size);
    filter: $effect($size);
  }
}

@mixin mi-bgimage-blur($url:null, $size:5px) {
  @if ($url) {
    background-image: url($url);
    background-size: cover;
    background-attachment: fixed;
  }
  @include mi-filter(blur, $size);
}

@mixin mi-opacity($opacity:1, $opacity_val: round($opacity * 100)) {
  opacity: $opacity;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $opacity_val + ")";
  filter: alpha(opacity=$opacity_val);
  zoom: 1;
}

@mixin mi-transform-scale($scale:1.25) {
  -webkit-transform: scale($scale); /* Safari and Chrome */
  -moz-transform: scale($scale); /* Firefox */
  -ms-transform: scale($scale); /* IE 9 */
  -o-transform: scale($scale); /* Opera */
  transform: scale($scale);
}

@mixin mi-multi-column($count: 3) {
  -moz-column-count: $count;
  -webkit-column-count: $count;
  -ms-column-count: $count;
  -o-column-count: $count;
  column-count: $count;
}

@mixin mi-multi-gap($width: 2rem) {
  -webkit-column-gap: $width;
  -moz-column-gap: $width;
  -ms-column-gap: $width;
  -o-column-gap: $width;
  column-gap: $width;
}
